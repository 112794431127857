<template>
  <v-app>
    <v-card flat width="100%" height="100%" color="#f1e6ee" class="lot_login">
      <v-card flat width="480" class="login-wrap">
        <v-card-title class="pa-0 text-center">
          <h1>
            블록체인 기반<br>
            <strong>사내 추첨 시스템</strong>
          </h1>
          <div class="pt-4 d-block h-desc"><h4 class="">비밀번호 초기화를 위한 사번을 입력바랍니다.</h4></div>
        </v-card-title>
        <v-card-text class="pa-10">
          <v-container class="pa-0 align-content-center align-center">
            <validation-observer v-slot="{ invalid }">
              <form>
                <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="ID"
                    :custom-messages="{ required: '초기화 할 사번을 입력하세요.' }"
                    class="relative"
                >
                  <v-text-field
                      :error-messages="errors"
                      v-model="id"
                      label="사번"
                      :rules="[rules.required, rules.min]"
                      autofocus solo flat outlined
                      height="50"
                      class="mt-0 pb-0 pt-0 rounded-sm f14"
                  ></v-text-field>
                </validation-provider>
                <v-btn
                    block depressed
                    :disabled="invalid || loading"
                    :loading="loading"
                    height="50"
                    color="primary"
                    class="mt-9 rounded-sm white--text"
                    @click="resetPw">확인
                </v-btn>
              </form>
            </validation-observer>
          </v-container>
        </v-card-text>
      </v-card>
      <div class="bg-area">
        <div class="symbol_01">
          <v-img :src="require('@/assets/images/ess_bg01.svg')"></v-img>
        </div>
        <div class="symbol_02">
          <v-img :src="require('@/assets/images/ess_bg02.svg')"></v-img>
        </div>
      </div>
    </v-card>
  </v-app>
</template>

<script>
import CryptoJS from 'crypto-js';
import storage from '../common/storage';

export default {
  name: 'Login',
  components: {},
  data() {
    return {
      id: null,
      loading: false,
      // password: 'Password',
      enNumReg: /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,25}$/,
      enNumSpeReg: /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/,
      rules: {
        required: (value) => !!value || '필수 항목입니다.',
        min: (v) => v.length >= 6 || '적어도 6글자 이상이어야 합니다.',
      },
    };
  },
  created() {
    this.$EventBus.$on('logout', this.onReceiveLogout);
    // 토큰 오류 경고창을 중첩하지 않도록
    this.$store.commit('setExpireDialog', false);
  },
  methods: {
    async resetPw() {
      this.loading = true;
      try {
        const result = await this.$axios.post('/auth/reset-password', {
          userId: this.id,
          password: CryptoJS.SHA256(this.id).toString(),
          prePassword: CryptoJS.SHA256(this.id).toString(),
        });
        console.log(result);
        if (result.status === 200) {
          alert('비밀번호가 변경되었습니다. 다시 로그인해주세요.');

          await this.$router.push({
            path: '/web/login',
          });
        }
      } catch (err) {
        await this.$dialog.warning({
          text: '비밀번호를 초기화 하는데 실패했습니다. 관리자에게 문의 하십시오.',
          title: '비밀번호 초기화 실패',
        });
        this.loginError = true;
        throw new Error(err);
      } finally {
        this.loading = false;
      }
    },
    go(path) {
      console.log(this.$route.path);
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
    submit() {
      console.log('submit');
    },
    onReceiveLogout() {
      storage.removeToken();
      storage.removeRefreshToken();
    },
  },
};
</script>
